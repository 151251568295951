import http from "../common/components/http-common";

export const ObtenerCampaignById = (
  id,
  type,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/v1/campaign/${type}/${id}`, config);
};

export const editarCampaign = (
  data,
  type,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };

  return http.patch<any>(`/v1/campaign/${type}/${data.id}`, data, config);
};

export const reprocesarCampaign = (
  campaignId,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };

  return http.post<any>(`v1/campaign-manager/reprocess/${campaignId}`, config);
};

export const downloadFile = (
  fileName: string,
  campaignId: string,
  type: string,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  const headers = { userName, viewName, viewFunction };
  const tipo = type.toLowerCase();

  return http
    .request({
      url: `/v1/campaign/${tipo}/downloadFile/${campaignId}`,
      method: "get",
      headers: headers,
      responseType: "blob", //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
