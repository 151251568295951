import React from "react";
import { Panel } from "primereact/panel";

export const CompCampaignDataSettings = ({ campaign }) => {
  const _vendor = campaign.configuracionVendor || null;
  const _type = sessionStorage.getItem("campanaType");

  return (
    <Panel className="mb-4">
      <div className={`data-settings ${_type}`}>
        <div className="column">
          <div className="dato single">
            <span className="label verde">Identificador</span>
            <span className="value">{campaign.id}</span>
          </div>

          <div className="dato single" style={{ marginTop: "8px" }}>
            <span className="label turquesa">Idioma</span>
            <span className="value">{campaign.langTranscript}</span>
          </div>
        </div>

        <div className="column">
          <div className="dato single">
            <span className="label celeste">Configuración TTS</span>

            <div className="lista">
              <span>
                Servicio: <strong>{campaign.configuracionTTS.service}</strong>
              </span>
              <span>
                Voz: <strong>{campaign.configuracionTTS.voiceName}</strong>
              </span>
            </div>
          </div>
        </div>

        <div className="column table">
          <div className="dato boolean">
            <span className="label">Procesado PBI</span>
            <span className="value">
              {campaign.inPBI ? (
                <i className="pi pi-check" style={{ color: "#689F38" }}></i>
              ) : (
                <i className="pi pi-times" style={{ color: "#EF4444" }}></i>
              )}
            </span>
          </div>

          <div className="dato boolean">
            <span className="label">Grabar llamadas</span>
            <span className="value">
              {campaign.grabarLlamadas ? (
                <i className="pi pi-check" style={{ color: "#689F38" }}></i>
              ) : (
                <i className="pi pi-times" style={{ color: "#EF4444" }}></i>
              )}
            </span>
          </div>

          <div className="dato boolean">
            <span className="label">Grabar transferencias</span>
            <span className="value">
              {campaign.grabarTransferencia ? (
                <i className="pi pi-check" style={{ color: "#689F38" }}></i>
              ) : (
                <i className="pi pi-times" style={{ color: "#EF4444" }}></i>
              )}
            </span>
          </div>
        </div>

        {_type === "saliente" && (
          <div className="column">
            <div className="dato boolean">
              <span className="label">Validar teléfonos</span>
              <span className="value">
                {campaign.telephoneValidation.active ? (
                  <i className="pi pi-check" style={{ color: "#689F38" }}></i>
                ) : (
                  <i className="pi pi-times" style={{ color: "#EF4444" }}></i>
                )}
              </span>
            </div>

            <div className="dato boolean">
              <span className="label">Activar AMD</span>
              <span className="value">
                {campaign.ejecutarAMD ? (
                  <i className="pi pi-check" style={{ color: "#689F38" }}></i>
                ) : (
                  <i className="pi pi-times" style={{ color: "#EF4444" }}></i>
                )}
              </span>
            </div>
          </div>
        )}

        {_vendor && (
          <div className="column">
            <div className="dato single">
              <span className="label violeta">Configuración proveedor</span>

              <div className="lista">
                <span>
                  Identificador: <strong>{_vendor.id && _vendor.id}</strong>
                </span>
                <span>
                  Nombre: <strong>{_vendor.name && _vendor.name}</strong>
                </span>
                <span>
                  Canales: <strong>{_vendor.channel && _vendor.channel}</strong>
                </span>
                <span>
                  Contexto:{" "}
                  <strong>{_vendor.context && _vendor.context}</strong>
                </span>
                <span>
                  CPS: <strong>{_vendor.cps && _vendor.cps}</strong>
                </span>
                <span>
                  Cola:{" "}
                  <strong>{_vendor.queueName && _vendor.queueName}</strong>
                </span>

                <span>Asterisks: </span>

                <span className="asterisks-list">
                  {_vendor.asterisks &&
                    _vendor.asterisks.map((a) => (
                      <span className="asterisk" key={a.id}>
                        <strong>{a.serverName}</strong>
                      </span>
                    ))}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
};
