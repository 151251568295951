import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { getDataChart } from "../../services/dashborad.service";
import { ChartBar } from "./ChartBar";
import { ChartDoughnut } from "./ChartDoughnut";
import { useMediaQuery } from "react-responsive";

export const ChartDetail = ({ id, verAvanzado }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const tipoGrafico = isMobile ? "doughnut" : "bar";
  const [tipo, setTipo] = useState(tipoGrafico);
  const [vistaAvanzada, setVistaAvanzada] = useState(false);

  const colores = [
    "#FBD7D2",
    "#CAE7E8",
    "#FFF8C5",
    "#C5CAE8",
    "#BDDEF6",
    "#F1EBDF",
    "#FEE0B1",
    "#DCEAC8",
  ];

  useEffect(() => {
    buildChart(id, vistaAvanzada);
  }, [vistaAvanzada]);

  const [data, setData] = useState([]);

  const buildChart = (id, vistaAvanzada) => {
    getDataChart(id, vistaAvanzada).then((response: any) => {
      try {
        setData(response.data.statusesGraphsResponses);
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          position: "absolute",
          top: "0px",
        }}
      >
        {verAvanzado && (
          <>
            <Button
              icon="pi pi-stop"
              disabled={!vistaAvanzada}
              style={{ padding: "2px" }}
              onClick={() => setVistaAvanzada(false)}
              className="p-button-secondary"
            />
            <Button
              icon="pi pi-th-large"
              disabled={vistaAvanzada}
              style={{ padding: "2px", marginLeft: "4px" }}
              onClick={() => setVistaAvanzada(true)}
              className="p-button-secondary"
            />
          </>
        )}

        {!isMobile && (
          <>
            <Button
              icon="pi pi-chart-bar"
              disabled={tipo === "bar"}
              style={{ padding: "2px", marginLeft: "16px" }}
              onClick={() => setTipo("bar")}
            />

            <Button
              icon="pi pi-chart-pie"
              disabled={tipo !== "bar"}
              style={{ padding: "2px", marginLeft: "4px" }}
              onClick={() => setTipo("doughnut")}
            />
          </>
        )}
      </div>

      {tipo === "bar" && data.length > 0 && (
        <ChartBar data={data} colores={colores} vistaAvanzada={vistaAvanzada} />
      )}

      {tipo === "doughnut" && data.length > 0 && (
        <ChartDoughnut
          data={data}
          colores={colores}
          vistaAvanzada={vistaAvanzada}
        />
      )}

      {data.length === 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>Sin datos</span>
        </div>
      )}
    </div>
  );
};
