import React, { useState, useRef, useEffect } from "react";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  reportStatus,
  generateReport,
  downloadReport,
} from "../../services/report.service";
import { reportTypes } from "../../types/report.type";
import { ReportConfirmationDialog } from "./ReportConfirmationDialog";

export const CompReport = ({ userName, viewName, viewFunction }) => {
  const toast = useRef(null);

  const { error, notRequest, requested, building, partial, ok } = reportTypes;
  const [estado, setEstado] = useState("");

  const tipoReporte = "VALID_TELEPHONE";

  const campanaId = sessionStorage.getItem("campanaId");
  const campanaName = sessionStorage.getItem("campanaName");
  const userId = localStorage.getItem("user");

  let timerPoint;
  const [reportId, setReportId] = useState(null);

  useEffect(() => {
    estadoReporte();
  }, []);

  const estadoReporte = () => {
    reportStatus(campanaId, tipoReporte, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          // setCargando(false);
          if (parsedData === partial || parsedData === ok)
            clearInterval(timerPoint);

          setReportId(parsedData.reportId);
          setEstado(parsedData.status);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          // setEstado(notRequest);
        }
        throw error;
      });
  };

  const generarReporte = () => {
    generateReport(
      campanaId,
      userId,
      tipoReporte,
      userName,
      viewName,
      viewFunction
    ).then((response: any) => {
      try {
        hideReportConfirmationDialog();
        // console.log(response.data);
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  const requestReport = () => {
    generarReporte();
    toast.current.show({
      severity: "success",
      summary: "Reportes",
      detail: "Se está generando el reporte",
      life: 2000,
    });
    setEstado(requested);
    timerPoint = setInterval(() => estadoReporte(), 2000);
  };

  const descargarReport = () => {
    downloadReport(
      reportId,
      campanaName,
      "VALID_TELEPHONE",
      userName,
      viewName,
      viewFunction
    );
    toast.current.show({
      severity: "success",
      summary: "Reportes",
      detail: "Se está descargando el reporte",
      life: 2000,
    });
    estadoReporte();
  };

  const [reportConfirmationDialog, setReportConfirmationDialog] =
    useState(false);
  const hideReportConfirmationDialog = () => {
    setReportConfirmationDialog(false);
  };

  const confirmarGeneracion = () => {
    setReportConfirmationDialog(true);
  };

  return (
    <div className="report">
      {(estado === error || estado === notRequest) && (
        <div className="flex align-items-center justify-content-end">
          <div>Generar Reporte</div>
          <Button
            icon={<TbReportAnalytics />}
            className="p-button p-component"
            style={{
              marginRight: ".5em",
              marginLeft: "10px",
              fontSize: "18px",
              lineHeight: "1",
              backgroundColor: "#7986CB",
              borderColor: "#7986CB",
            }}
            onClick={() => requestReport()}
          />
        </div>
      )}

      {(estado === requested || estado === building) && (
        <div className="flex align-items-center justify-content-end">
          <div>Generando Reporte</div>
          <Button
            icon="pi pi-spin pi-spinner"
            className="p-button p-component p-button-success"
            disabled={true}
            style={{
              marginRight: ".5em",
              marginLeft: "10px",
              fontSize: "18px",
              lineHeight: "1",
            }}
          />
        </div>
      )}

      {(estado === partial || estado === ok) && (
        <div className="flex align-items-center justify-content-end">
          <>
            <div>Generar nuevo reporte</div>
            <Button
              icon={<TbReportAnalytics />}
              className="p-button p-component"
              style={{
                marginLeft: "10px",
                fontSize: "18px",
                lineHeight: "1",
                backgroundColor: "#7986CB",
                borderColor: "#7986CB",
              }}
              onClick={() => confirmarGeneracion()}
            />
          </>
          <>
            <Button
              icon={<AiOutlineCloudDownload />}
              className="p-button p-component p-button p-button-success"
              style={{
                marginRight: ".5em",
                marginLeft: "10px",
                fontSize: "18px",
                lineHeight: "1",
              }}
              onClick={() => descargarReport()}
              disabled={reportId === null}
            />
          </>
        </div>
      )}

      <ReportConfirmationDialog
        reportConfirmationDialog={reportConfirmationDialog}
        hideReportConfirmationDialog={hideReportConfirmationDialog}
        generarReporte={requestReport}
        tipoReporte={"VALID_TELEPHONE"}
      />
      <Toast ref={toast} />
    </div>
  );
};
