import React from "react";
import { Panel } from "primereact/panel";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { downloadFile } from "../services/campaign.service";
import { ShowView } from "../common/components/ShowView";
import { viewTypes } from "../common/types/views.type";

export const CompFinalUser = ({ campaign }) => {
  const {
    vnDetalleCampana: viewName,
    vfVerDetalleCampana: viewFunction,
    vfDescargarArchivo,
  } = viewTypes;

  const userName = localStorage.getItem("userName");
  const showDescargarArchivo = ShowView(vfDescargarArchivo, viewName);
  const _type = campaign.surveyTipo;

  const getFile = () => {
    downloadFile(
      campaign.fileContact.filename,
      campaign.id,
      campaign.surveyTipo,
      userName,
      viewName,
      viewFunction
    );
  };

  return (
    <Panel className="mb-4">
      <div className="comp-final-user">
        <div className="column">
          <label className="comentario" htmlFor="desciption">
            Comentario
          </label>
          <InputTextarea
            value={campaign.comentario || ""}
            readOnly={true}
            rows={5}
            cols={30}
            style={{ resize: "none" }}
          />
        </div>

        <div className="column">
          {campaign && showDescargarArchivo && _type === "Saliente" && (
            <div
              className="filed"
              style={{
                alignItems: "center",
                display: "flex",
                padding: "0 6px",
              }}
            >
              <Button
                className="file-download"
                icon="pi pi-download"
                onClick={() => getFile()}
                style={{ marginRight: "6px", height: "32px" }}
              />
              <label htmlFor="fileName">
                <small>
                  {campaign.fileContact && campaign.fileContact.filename}
                </small>
              </label>
            </div>
          )}
        </div>

        <div className="column"></div>
      </div>
    </Panel>
  );
};
