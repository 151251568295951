import React from "react";
import { ChartDetail } from "../charts/ChartDetail";
import { Button } from "primereact/button";
import { normalizeName, styleEstado } from "../../utils/table.uitls";

export const Saliente = ({
  indicadores,
  horarios,
  diaActual,
  handleCalendar,
  campaign,
  showGraficoAvanzado,
  Duration,
  showDuration,
}) => {
  return (
    <>
      <div className="dash-container">
        <div className="column">
          <div className="demi-content">
            <div className="demi-column card grid-nogutter widget-overview-box widget-overview-box-3 card-detail">
              <span className="overview-icon">
                <i className="pi pi-tag"></i>
              </span>
              <span className="overview-title">Estado</span>
              <div className="grid overview-detail">
                <div className="col-6">
                  <span className="mr-3">
                    <div className="overview-number">
                      {indicadores["porcentajeProgreso"]}%
                    </div>
                    {horarios && horarios.length > 0 && (
                      <div className="horariosCampaingDetail">
                        <Button
                          label={diaActual}
                          icon="pi pi-calendar"
                          iconPos="right"
                          className="p-button-outlined"
                          type="button"
                          onClick={(e) => handleCalendar()}
                          tooltip="Ver todos los rangos horarios"
                          style={{ marginTop: "4px" }}
                        />
                      </div>
                    )}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className={
                      "status customer-badge status-" +
                      styleEstado(campaign.estado) +
                      " text-status text-size-status"
                    }
                    style={{ top: 0 }}
                  >
                    {normalizeName(campaign.estado)}
                  </span>
                </div>
              </div>
            </div>

            <div className="demi-column top card grid-nogutter widget-overview-box widget-overview-box-4 card-detail">
              <span className="overview-icon">
                <i className="pi pi-book"></i>
              </span>
              <span className="overview-title">Registros</span>
              <div className="grid overview-detail">
                <div className="col-4">
                  <div className="overview-number">
                    {indicadores["registrosTotales"].toLocaleString("es-AR")}
                  </div>
                  <div className="overview-subtext">Total</div>
                </div>
                <div className="col-4 box-indicators">
                  <div className="overview-number">
                    {indicadores["registrosValidosListos"].toLocaleString(
                      "es-AR"
                    )}
                  </div>
                  <div className="overview-subtext">Válidos</div>
                </div>
                <div className="col-4">
                  <div className="overview-number">
                    {indicadores["registrosInvalidos"].toLocaleString("es-AR")}
                  </div>
                  <div className="overview-subtext">No Válidos</div>
                </div>
              </div>
            </div>
          </div>

          <div className={showDuration ? "demi-content" : ""}>
            <div
              className={
                showDuration
                  ? "demi-column card grid-nogutter widget-overview-box widget-overview-box-1 card-detail"
                  : "card grid-nogutter widget-overview-box widget-overview-box-1 card-detail"
              }
            >
              <span className="overview-icon">
                <i className="pi pi-users"></i>
              </span>
              <span className="overview-title">Resumen resultados</span>
              <div className="grid overview-detail">
                <div className="col-4">
                  <div className="overview-number">
                    {indicadores["porcentajeCasosExitosos"].toLocaleString(
                      "es-AR"
                    )}
                    %
                  </div>
                  <div className="overview-subtext">
                    {indicadores["casosExitosos"].toLocaleString("es-AR")}
                  </div>
                  <div className="overview-subtext">
                    <strong>Contactos efectivos</strong>
                  </div>
                </div>
                <div className="col-4 box-indicators">
                  <div className="overview-number">
                    {(
                      indicadores["porcentajeContestadores"] +
                      indicadores["porcentajeBlancos"]
                    ).toLocaleString("es-AR")}
                    %
                  </div>
                  <div className="overview-subtext fontSubText">
                    {(
                      indicadores["totalContestadores"] +
                      indicadores["totalBlancos"]
                    ).toLocaleString("es-AR")}
                  </div>
                  {/* TODO cuando se cambien los indicadores revisar estas sumas turbia */}
                  <div className="overview-subtext fontSubText">
                    <strong>Contestador</strong>
                  </div>
                </div>
                <div className="col-4">
                  <div className="overview-number">
                    {indicadores["porcentajeNoSePudoContactar"].toLocaleString(
                      "es-AR"
                    )}
                    %
                  </div>
                  <div className="overview-subtext fontSubText">
                    {indicadores["totalNoSePudoContactar"].toLocaleString(
                      "es-AR"
                    )}
                  </div>
                  <div className="overview-subtext fontSubText">
                    <strong>No se pudo contactar</strong>
                  </div>
                </div>
              </div>
            </div>

            {showDuration && (
              <div className="demi-column card grid-nogutter widget-overview-box widget-overview-box-2 card-detail">
                <span className="overview-icon">
                  <i className="pi pi-clock"></i>
                </span>
                <span className="overview-title">Tiempo total voicebot</span>
                <div className="grid overview-detail">
                  <div className="col-12">
                    <div className="overview-number">
                      <Duration campaignId={campaign.id} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="column">
          <div className="grafico-container">
            <div className="card grid-nogutter widget-overview-box widget-overview-box-2 card-detail">
              <ChartDetail
                id={indicadores["_id"]}
                verAvanzado={showGraficoAvanzado}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
