import { ToggleButton } from "primereact/togglebutton";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { Button } from "primereact/button";
import { TbRecycle } from "react-icons/tb";

export const CompMostrar = ({
  campaign,
  cambiarDato,
  loadingEsVisible,
  setLoadingEsVisible,
  loadingMostrarAudios,
  setLoadingMostrarAudios,
  loadingMostrarReproc,
  setLoadingMostrarReproc,
  reprocesar,
}) => {
  const {
    vnDetalleCampana: viewName,
    vfMostrarCampana,
    vfMostrarAudio,
    vfMostrarReprocesamiento,
  } = viewTypes;

  const showMostrar = ShowView(vfMostrarCampana, viewName);
  const showAudios = ShowView(vfMostrarAudio, viewName);
  const showReprocesamiento = ShowView(vfMostrarReprocesamiento, viewName);
  const _finalizada = campaign.estado === "FINALIZADA" ? true : false;
  const _type = campaign.surveyTipo;

  const handleVisible = (value) => {
    cambiarDato({
      id: campaign.id,
      esVisible: value,
    });
    setLoadingEsVisible(true);
  };

  const handleAudios = (value) => {
    cambiarDato({
      id: campaign.id,
      mostrarAudios: value,
    });
    setLoadingMostrarAudios(true);
  };

  const handleReproc = () => {
    setLoadingMostrarReproc(true);
    reprocesar(campaign.id);
  };

  return (
    <div className="comp-mostrar">
      {showMostrar && (
        <ToggleButton
          checked={campaign.esVisible}
          onChange={(e) => handleVisible(e.value)}
          onIcon={loadingEsVisible ? "pi pi-spin pi-spinner" : "pi pi-check"}
          disabled={loadingEsVisible}
          offIcon={loadingEsVisible ? "pi pi-spin pi-spinner" : "pi pi-times"}
          onLabel="Campaña visible"
          offLabel="Campaña oculta"
          className="toggle"
        />
      )}
      {showAudios && (
        <ToggleButton
          checked={campaign.mostrarAudios}
          onChange={(e) => handleAudios(e.value)}
          onIcon={
            loadingMostrarAudios ? "pi pi-spin pi-spinner" : "pi pi-check"
          }
          disabled={loadingMostrarAudios}
          offIcon={
            loadingMostrarAudios ? "pi pi-spin pi-spinner" : "pi pi-times"
          }
          onLabel="Audios visibles"
          offLabel="Audios ocultos"
          className="toggle"
        />
      )}
      {!_finalizada && _type === "Saliente" && showReprocesamiento && (
        <Button
          className="reproc"
          label="Reprocesar"
          icon={
            loadingMostrarReproc ? (
              "pi pi-spin pi-spinner"
            ) : (
              <TbRecycle style={{ marginRight: "8px" }} />
            )
          }
          onClick={() => handleReproc()}
          disabled={loadingMostrarReproc}
        />
      )}
    </div>
  );
};
