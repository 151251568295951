import { useEffect, useRef, useState } from "react";
import SurveyTable from "./SurveyTable";
import Indicators from "./indicators/Indicators";
import Container from "react-bootstrap/Container";
import { Toolbar } from "primereact/toolbar";
import { CompMostrar } from "./CompMostrar";
import { MultiReport } from "./reports/MultiReport";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { ActionsComponent } from "./ActionsComponent";
import {
  ObtenerCampaignById,
  editarCampaign,
  reprocesarCampaign,
} from "../services/campaign.service";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { CompCampaignDataSettings } from "./CompCampaignDataSettings";
import { Toast } from "primereact/toast";
import { CompFinalUser } from "./CompFinalUser";

export default function CampaignDetail(props) {
  useEffect(() => {
    getCampaign();
  }, []);

  const _id = sessionStorage.getItem("campanaId");
  const type = sessionStorage.getItem("campanaType");
  const toast = useRef(null);

  const {
    vnDetalleCampana: viewName,
    vfVerDetalleCampana: viewFunction,
    vfDescargarReportesDet,
    vfMostrarCampana,
    vfMostrarAudio,
    vfFuncionPlayPauseStop,
    vfVerSeteosCampana,
  } = viewTypes;
  const userName = localStorage.getItem("userName");

  const showMostrar = ShowView(vfMostrarCampana, viewName);
  const showAudios = ShowView(vfMostrarAudio, viewName);
  const showActions = ShowView(vfFuncionPlayPauseStop, viewName);
  const showDescargar = ShowView(vfDescargarReportesDet, viewName);
  const showSettings = ShowView(vfVerSeteosCampana, viewName);

  const [loadingButton, setLoadingButton] = useState(false);
  const [campaign, setCampign] = useState(null);

  const getCampaign = () => {
    ObtenerCampaignById(_id, type, userName, viewName, viewFunction).then(
      (response: any) => {
        try {
          let parsedData = response.data;

          setCampign(parsedData);
          sessionStorage.setItem("campanaUserId", parsedData.userId);
          setConfirmationCampaignName(parsedData.nombre);
        } catch (err) {
          const error = err as { message?: string };
        }
      }
    );
  };

  const cambiarDato = (_data) => {
    editarCampaign(_data, type, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          getCampaign();
          _data.hasOwnProperty("estado") && setLoadingButton(false);
          _data.hasOwnProperty("esVisible") && setLoadingEsVisible(false);
          _data.hasOwnProperty("mostrarAudios") &&
            setLoadingMostrarAudios(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {});
  };

  const [loadingEsVisible, setLoadingEsVisible] = useState(false);
  const [loadingMostrarAudios, setLoadingMostrarAudios] = useState(false);
  const [loadingMostrarReproc, setLoadingMostrarReproc] = useState(false);

  /** */
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationCampaignName, setConfirmationCampaignName] =
    useState(null);

  const hideConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const cambiarEstado = (estado) => {
    setLoadingButton(true);
    const _data = {
      id: _id,
      estado,
    };

    editarCampaign(_data, type, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          hideConfirmationDialog();
          setLoadingButton(false);
          getCampaign();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {});
  };

  const reprocesar = (campaignId) => {
    const _data = {
      idEncuesta: campaignId,
    };

    reprocesarCampaign(_data, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          setLoadingMostrarReproc(false);
          // toast.current.show({
          //   severity: "success",
          //   summary: "Reprocesar",
          //   detail: response.data.message,
          //   life: 3000,
          // });
        } catch (err) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
      })
      .catch((e) => {});
  };

  /** */

  const leftToolbarTemplate = () => {
    return (
      <>
        {showDescargar && (
          <MultiReport
            userName={userName}
            viewName={viewName}
            viewFunction={vfDescargarReportesDet}
            campaign={campaign}
          />
        )}

        {campaign && (
          <CompMostrar
            campaign={campaign}
            cambiarDato={cambiarDato}
            loadingMostrarAudios={loadingMostrarAudios}
            setLoadingMostrarAudios={setLoadingMostrarAudios}
            loadingEsVisible={loadingEsVisible}
            setLoadingEsVisible={setLoadingEsVisible}
            loadingMostrarReproc={loadingMostrarReproc}
            setLoadingMostrarReproc={setLoadingMostrarReproc}
            reprocesar={reprocesar}
          />
        )}
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        {showActions && campaign && campaign.estado !== "FINALIZADA" && (
          <ActionsComponent
            campaign={campaign}
            cambiarEstado={cambiarEstado}
            loadingButton={loadingButton}
            setConfirmationDialog={setConfirmationDialog}
          />
        )}
      </>
    );
  };

  return (
    <Container className="evi-campaign-detail">
      <Toast ref={toast} />
      {((showActions && campaign && campaign.estado !== "FINALIZADA") ||
        showDescargar ||
        showAudios ||
        showMostrar) && (
        <Toolbar
          className="mb-4 actions-panel"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
          style={{ minHeight: "55px" }}
        ></Toolbar>
      )}

      {showSettings && campaign && (
        <CompCampaignDataSettings campaign={campaign} />
      )}

      {campaign && (
        <>
          <Indicators campaign={campaign} />
          <CompFinalUser campaign={campaign} />
          <SurveyTable
            showDescargar={showDescargar}
            estado={campaign.estado}
            viewId={showSettings}
          />
        </>
      )}

      <ConfirmationDialog
        confirmationDialog={confirmationDialog}
        hideConfirmationDialog={hideConfirmationDialog}
        confirmationCampaignName={confirmationCampaignName}
        cambiarEstado={cambiarEstado}
        loadingButton={loadingButton}
      />
    </Container>
  );
}
