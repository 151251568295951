import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Calendar, momentLocalizer } from "react-big-calendar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/es";
import { normalizeName, styleEstado } from "../../utils/table.uitls";
import { Toolbar } from "primereact/toolbar";

export const CalendarPanelContain = ({
  data,
  showEstadosALlamar,
  closePanel,
}) => {
  const colores = [
    "#FBD7D2",
    "#CAE7E8",
    "#FFF8C5",
    "#C5CAE8",
    "#BDDEF6",
    "#F1EBDF",
    "#FEE0B1",
    "#DCEAC8",
  ];

  const localizer = momentLocalizer(moment);
  const [eventos, setEventos] = useState(null);

  useEffect(() => {
    data.horarios && convertirAEventosParaCalendar(data.horarios);
  }, []);

  const convertirAEventosParaCalendar = (horarios) => {
    const eventos = [];

    for (const horario of horarios) {
      const { date, rangos } = horario;
      const fechaBase = moment(date, "YYYY-MM-DD");

      for (const rango of rangos) {
        const { from, to, estadosALlamar } = rango;
        const horaInicio = moment(from, "HH:mm");
        const horaFin = moment(to, "HH:mm");

        const estadosALlamarStr = (estadosALlamar || [])
          .map(({ estado }) => estado.toUpperCase())
          .join(" | ");

        const estadosStr =
          showEstadosALlamar && data.surveyTipo === "Saliente"
            ? " - " + estadosALlamarStr
            : "";

        const fechaInicio = fechaBase
          .clone()
          .add(horaInicio.hours(), "hours")
          .add(horaInicio.minutes(), "minutes");
        const fechaFin = fechaBase
          .clone()
          .add(horaFin.hours(), "hours")
          .add(horaFin.minutes(), "minutes");

        if (fechaInicio.isValid() && fechaFin.isValid()) {
          const evento = {
            title: `${data.nombre || ""} ${estadosStr}`,
            start: fechaInicio.toDate(),
            end: fechaFin.toDate(),
            color: colores[Math.floor(Math.random() * colores.length)],
          };

          eventos.push(evento);
        }
      }
    }
    setEventos(eventos);
  };

  const getEventStyle = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        color: "#495057",
      },
    };
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="panel-title">{data.nombre}</span>
        <span
          className={
            "customer-badge status-" + styleEstado(data.estado) + " text-status"
          }
          style={{ marginLeft: "8px" }}
        >
          {normalizeName(data.estado)}
        </span>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-times"
            label="Cerrar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  // const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 }).toDate();

  const formTemplate = (
    <div className="body">
      {eventos && (
        <Calendar
          style={{ width: "100%" }}
          localizer={localizer}
          events={eventos}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          views={["day", "week"]}
          defaultView="day"
          step={30}
          timeslots={2}
          eventPropGetter={getEventStyle}
          defaultDate={eventos.length > 0 ? eventos[0].start : new Date()}
          // min={minTime}
        />
      )}
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
