import React from "react";
import { BsPlayCircle, BsPauseCircle, BsStopCircle } from "react-icons/bs";
import { Button } from "primereact/button";
import { normalizeName, styleEstado } from "../utils/table.uitls";

export const ActionsComponent = ({
  campaign,
  loadingButton,
  setConfirmationDialog,
  cambiarEstado,
}) => {
  return (
    <div className="controls">
      <span
        className={
          "label status customer-badge status-" +
          styleEstado(campaign.estado) +
          " text-status text-size-status"
        }
      >
        {normalizeName(campaign.estado)}
      </span>

      {campaign.estado === "ACTIVA" && (
        <Button
          icon={<BsPauseCircle />}
          onClick={() => cambiarEstado("PAUSADA")}
          disabled={loadingButton}
          className="action pause"
        />
      )}
      {campaign.estado === "PAUSADA" && (
        <Button
          icon={<BsPlayCircle />}
          onClick={() => cambiarEstado("ACTIVA")}
          disabled={loadingButton}
          className="action play"
        />
      )}

      <Button
        icon={<BsStopCircle />}
        onClick={() => setConfirmationDialog(true)}
        disabled={loadingButton}
        className="action stop"
      />
    </div>
  );
};
