import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import moment from "moment";
import { Button } from "primereact/button";

export const BarridosList = ({
  horariosRaw,
  horarios,
  handleBarrido,
  selectedRangos,
  setSelectedRangos,
  disabledRangos,
  validarRangoConBarrido,
}) => {
  const toast = useRef(null);

  const yaExiste = (rango) => {
    const rangoStr = JSON.stringify(rango);
    return selectedRangos.some((item) => JSON.stringify(item) === rangoStr);
  };

  const esMismoDia = (rango) => {
    if (selectedRangos.length === 0) {
      return true;
    }
    const rangoId = rango.id;
    for (const item of selectedRangos) {
      if (item.id === rangoId) {
        return true;
      }
    }
    return false;
  };

  const toggleSelectedRango = (rango) => {
    if (esMismoDia(rango)) {
      if (yaExiste(rango)) {
        const existingKeys = selectedRangos.map((r) => r.key);
        const firstSelectedKey =
          existingKeys.length > 0 ? Math.min(...existingKeys) : -1;
        if (rango.key > firstSelectedKey) {
          setSelectedRangos((prevRangos) =>
            prevRangos.filter((item) => item.key < rango.key)
          );
        } else {
          setSelectedRangos((prevRangos) =>
            prevRangos.filter((item) => item.key !== rango.key)
          );
        }
      } else {
        if (validarRangoConBarrido(rango)) {
          if (selectedRangos.length === 0) {
            setSelectedRangos([...selectedRangos, rango]);
          } else {
            const newSelectedRangos = [...selectedRangos];
            const existingKeys = newSelectedRangos.map((r) => r.key);
            const lastSelectedKey =
              existingKeys.length > 0 ? Math.max(...existingKeys) : -1;
            const firstSelectedKey =
              existingKeys.length > 0 ? Math.min(...existingKeys) : -1;

            if (rango.key > lastSelectedKey) {
              for (let key = lastSelectedKey + 1; key < rango.key; key++) {
                if (disabledRangos.some((disabled) => disabled.key === key)) {
                  break;
                }
                const rangoToAdd = horarios.find((item) => item.key === key);
                if (rangoToAdd) {
                  newSelectedRangos.push(rangoToAdd);
                  existingKeys.push(key);
                }
              }
            }

            if (rango.key < firstSelectedKey) {
              for (let key = firstSelectedKey - 1; key > rango.key; key--) {
                if (disabledRangos.some((disabled) => disabled.key === key)) {
                  break;
                }
                const rangoToAdd = horarios.find((item) => item.key === key);
                if (rangoToAdd) {
                  newSelectedRangos.push(rangoToAdd);
                  existingKeys.push(key);
                }
              }
            }

            if (
              !disabledRangos.some((disabled) => disabled.key === rango.key)
            ) {
              newSelectedRangos.push(rango);
            }
            const _newSelectedRangos = newSelectedRangos.sort(
              (a, b) => a.key - b.key
            );
            setSelectedRangos(_newSelectedRangos);
          }
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Advertencia",
            detail: "No se pueden agregar rangos intermedios",
            life: 3000,
          });
        }
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Advertencia",
        detail: "No se pueden agregar rangos de diferentes días",
        life: 3000,
      });
    }
  };

  const handleAddToSelectedList = () => {
    handleBarrido(selectedRangos);
  };

  return (
    <div className="horarios-list">
      <Toast ref={toast} />
      <div>
        {horariosRaw.map((raw) => (
          <div key={raw}>
            <div className="date-title">
              {raw.day} {raw.date && moment(raw.date).format("DD/MM/YYYY")}
            </div>

            {horarios.map((horario) => (
              <HorarioItem
                key={horario.key}
                horario={horario}
                selectedRangos={selectedRangos}
                disabledRangos={disabledRangos}
                toggleSelectedRango={toggleSelectedRango}
                date={raw.date}
              />
            ))}
          </div>
        ))}
      </div>

      <div className="footer">
        <Button
          label="Agregar barrido"
          icon="pi pi-plus"
          onClick={handleAddToSelectedList}
          disabled={selectedRangos.length === 0}
        />
      </div>
    </div>
  );
};

const HorarioItem = ({
  horario,
  selectedRangos,
  disabledRangos,
  toggleSelectedRango,
  date,
}) => {
  return (
    <>
      {horario.date === date && (
        <div className="horario-item">
          <input
            type="checkbox"
            className={
              disabledRangos.some((disabled) => disabled.key === horario.key)
                ? "custom-checkbox disabled"
                : "custom-checkbox"
            }
            onChange={() => toggleSelectedRango(horario)}
            checked={selectedRangos.some(
              (selected) => selected.key === horario.key
            )}
            disabled={disabledRangos.some(
              (disabled) => disabled.key === horario.key
            )}
          />
          {selectedRangos.some((selected) => selected.key === horario.key) && (
            <i className="pi pi-check checked-icon"></i>
          )}
          <span
            className={
              disabledRangos.some((disabled) => disabled.key === horario.key)
                ? "label-disabled"
                : "label"
            }
          >
            {horario.rango.from} - {horario.rango.to}
          </span>
        </div>
      )}
    </>
  );
};
