import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { FilterWrapper } from "./_filterPanel/FilterWrapper";
import { normalizeName, styleEstadoLlamado } from "../utils/table.uitls";
import moment from "moment";

export const FilterPanel = ({
  lazyParams,
  setLazyParams,
  initParams,
  optionsSelect,
  viewNombre,
  viewDNI,
  viewAsterisk,
}) => {
  const initFilters = {
    startDate: "",
    documento: "",
    nombre: "",
    destinationNumber: "",
    estadoFinal: "",
    asterisk: "",
  };

  const [filters, setFilters] = useState(initFilters);
  const [fechaHora, setFechaHora] = useState(null);

  const statusBodyTemplateSelect = (data) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstadoLlamado(data.name) +
          " text-status"
        }
      >
        {normalizeName(data.name)}
      </span>
    );
  };

  const selectedStatesTemplate = (option) => {
    if (option) {
      return (
        <div
          className={
            "customer-badge status-" +
            styleEstadoLlamado(option) +
            " text-status small"
          }
          style={{ display: "inlineBlock" }}
        >
          <div>{option}</div>
        </div>
      );
    }
    return "Ingresá el estado";
  };

  const filtros = [
    {
      title: "Teléfono",
      filtro: "destinationNumber",
      input: (
        <InputText
          className="filter-text"
          value={filters.destinationNumber}
          placeholder="Ingresa el teléfono"
        />
      ),
    },
    {
      title: "Fecha",
      filtro: "startDate",
      input: (
        <Calendar
          value={fechaHora}
          onChange={(e) => handleChange("startDate", e.value)}
          showTime
          showSeconds
          dateFormat="dd/mm/yy"
          placeholder="dd/mm/yy hh:mm:ss"
        />
      ),
    },
    {
      title: "Estado",
      filtro: "estadoFinal",
      input: (
        <div className="select-container">
          <MultiSelect
            value={filters.estadoFinal}
            options={optionsSelect}
            onChange={(e) => handleChange("estadoFinal", e.value)}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Ingresá el estado"
            itemTemplate={statusBodyTemplateSelect}
            selectedItemTemplate={selectedStatesTemplate}
          />
        </div>
      ),
    },
  ];

  if (viewNombre) {
    filtros.push({
      title: "Nombre",
      filtro: "nombre",
      input: (
        <InputText
          className="filter-text"
          value={filters.nombre}
          placeholder="Ingresa el nombre"
        />
      ),
    });
  }

  if (viewDNI) {
    filtros.push({
      title: "Documento",
      filtro: "documento",
      input: (
        <InputText
          className="filter-text"
          value={filters.documento}
          placeholder="Ingresa el documento"
        />
      ),
    });
  }

  if (viewAsterisk) {
    filtros.push({
      title: "Asterisk",
      filtro: "asterisk",
      input: (
        <InputText
          className="filter-text"
          value={filters.asterisk}
          placeholder="Ingresa el asterisk"
        />
      ),
    });
  }

  /** */
  const handleChange = (name, value) => {
    if (name !== "startDate") {
      setFilters((prevEstado) => ({
        ...prevEstado,
        [name]: value,
      }));
      setLazyParams((prevEstado) => ({
        ...prevEstado,
        [name]: value,
      }));
    } else {
      const fechaSeleccionada = moment(value);
      setFechaHora(fechaSeleccionada.toDate());
      setLazyParams((previousState) => {
        return {
          ...previousState,
          startDate: fechaSeleccionada.format("YYYY-MM-DD"),
          horaInicio: fechaSeleccionada.format("HH:mm:ss"),
        };
      });
    }
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
      setFechaHora={setFechaHora}
    />
  );
};
