import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export const ConfirmationDialog = ({
  confirmationDialog,
  hideConfirmationDialog,
  confirmationCampaignName,
  cambiarEstado,
  loadingButton,
}) => {
  const confirmationDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => handleChange()}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideConfirmationDialog}
      />
    </>
  );

  const handleChange = () => {
    cambiarEstado("FINALIZADA");
  };

  return (
    <Dialog
      visible={confirmationDialog}
      style={{ width: "400px", maxWidth: "95%" }}
      header="Finalizar campaña"
      modal
      footer={confirmationDialogFooter}
      onHide={hideConfirmationDialog}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className={
            loadingButton
              ? "pi pi-spin pi-spinner"
              : "pi pi-exclamation-triangle mr-3"
          }
          style={
            loadingButton
              ? { fontSize: "2rem", opacity: ".4", marginRight: "16px" }
              : { fontSize: "2rem" }
          }
        />
        <span>
          ¿Estas seguro de que quieres finalizar la campaña
          <br />
          <b>{confirmationCampaignName}</b>?
        </span>
      </div>
    </Dialog>
  );
};
