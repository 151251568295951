export const styleEstado = (estado) => {
  let estadoSwitch = estado.toUpperCase();
  switch (estadoSwitch) {
    case "ACTIVA":
      return "new";
    case "PAUSADA":
      return "negotiation";
    case "EN_ESPERA":
      return "renewal";
    case "CONFIGURANDO_CAMPANNA":
      return "proposal";
    case "PENDIENTE_POR_PROCESAR":
      return "renewal";
    case "FINALIZADA":
      return "qualified";
    case "ERROR_BATCH":
      return "unqualified";
    default:
      return "other";
  }
};

export const styleEstadoLlamado = (estado) => {
  let estadoSwitch = estado.toUpperCase();
  switch (estadoSwitch) {
    case "NÚMERO INVÁLIDO":
      return "unqualified";
    case "CONTESTADOR":
      return "negotiation";
    case "NO SE PUDO CONTACTAR":
      return "renewal";
    case "PENDIENTE LLAMAR":
      return "new";
    default:
      return "qualified";
  }
};

export const normalizeName = (estado) => {
  switch (estado) {
    case "ACTIVA":
      return "ACTIVA";
    case "PAUSADA":
      return "PAUSADA";
    case "EN_ESPERA":
      return "EN ESPERA";
    case "CONFIGURANDO_CAMPANNA":
      return "CONFIGURANDO CAMPAÑA";
    case "PENDIENTE_POR_PROCESAR":
      return "PENDIENTE POR PROCESAR";
    case "FINALIZADA":
      return "FINALIZADA";
    case "ERROR_BATCH":
      return "ERROR";
    default:
      return estado;
  }
};
