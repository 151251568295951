import http from "../common/components/http-common";

export const getDataChart = (
  id,
  vistaAvanzada,
  userName,
  viewName,
  viewFunction
) => {
  const _endpoint = vistaAvanzada
    ? `/v2/campaign/dashboardGraphs/${id}/allStatuses`
    : `/v2/campaign/dashboardGraphs/${id}`;

  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(_endpoint, config);
};
