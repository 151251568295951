import http from "../common/components/http-common";
import { ISurveyFilter } from "../types/survey.type";
var qs = require("qs");

class ListDataService {
  obtenerSurvey(
    idEncuesta,
    data: ISurveyFilter,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let parameter = {
      destinationNumber: data.destinationNumber,
      estadosFinal: data.estadoFinal,
      startDate: data.startDate,
      horaInicio: data.horaInicio,
      nombreKey: data.nombreKey,
      nombreValue: data.nombreValue,
      dniKey: data.dniKey,
      dniValue: data.dniValue,
      direction: data.sort.direction,
      field: data.sort.field,
      asterisk: data.asterisk,
    };

    let config = {
      headers: {
        pageSize: data.pagination.pageSize,
        pageNumber: data.pagination.pageNumber,
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: parameter,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "brackets" });
      },
    };

    return http.get<ISurveyFilter>(
      "/surveyResponse/listar/" + idEncuesta,
      config
    );
  }

  obtenerIndicator(idEncuesta) {
    let parameter = {
      encuesta: idEncuesta,
      esWebCliente: true,
    };

    let config = {
      params: parameter,
    };
    return http.get<any>("/encuestas/indicators", config);
  }

  ObtenerStatus(idEncuesta) {
    return http.get<any>("/encuestas/estados/" + idEncuesta);
  }
}

export default new ListDataService();
