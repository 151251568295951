import React, { useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { useMediaQuery } from "react-responsive";
import { FilterComponent } from "./FilterComponent";

export const FilterWrapper = ({
  lazyParams,
  setLazyParams,
  initFilters,
  filtros,
  initParams,
  filters,
  setFilters,
  handleChange,
  setFechaHora,
}) => {
  //** **//

  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const [countFilters, setCountFilters] = useState(0);

  const fields = Object.keys(filters);

  const countActiveFilters = () => {
    const countArray = (campo, count) => {
      if (Array.isArray(campo) && campo.length === 0) {
        return count;
      } else if (campo === "") {
        return count;
      } else {
        return count + 1;
      }
    };
    let _count = 0;
    fields.forEach((field) => {
      _count = countArray(lazyParams[field], _count);
    });
    setCountFilters(_count);
  };

  const changeFilter = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  const cleanFilters = () => {
    setLazyParams(initParams);
    setFilters(initFilters);
    setFechaHora(undefined);
  };

  const cleanEsteFiltro = (propiedad) => {
    if (propiedad === "startDate") {
      setFechaHora(undefined);
    }
    setLazyParams((previousState) => {
      return {
        ...previousState,
        [propiedad]: "",
      };
    });
    changeFilter(propiedad, "");
  };

  useEffect(() => {
    countActiveFilters();
  }, [lazyParams]);

  const headerTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = options.className;

    return (
      <div className={className}>
        <button
          className={
            countFilters === 0
              ? "filter-button pi pi-filter"
              : "filter-button pi pi-filter-slash"
          }
          disabled={countFilters === 0 ? true : false}
          onClick={() => cleanFilters()}
        ></button>
        {countFilters !== 0 && (
          <span className="count-filter">{countFilters}</span>
        )}
        <span className="filter-title">Filtros</span>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
      </div>
    );
  };

  const filtroActivo = (filtro) => {
    let check: boolean = true;

    if (Array.isArray(filtro) && filtro.length === 0) {
      check = false;
    } else if (filtro === "") {
      check = false;
    }
    return check;
  };

  return (
    <div className="filter-panel">
      <Panel
        headerTemplate={headerTemplate}
        collapsed={isMobile ? true : false}
        toggleable={true}
      >
        <div className="filter-contain">
          {filtros.map((filter, index) => (
            <FilterComponent
              key={index}
              title={filter.title}
              filtro={filter.filtro}
              lazyParams={lazyParams}
              filtroActivo={filtroActivo}
              cleanEsteFiltro={cleanEsteFiltro}
              filters={filters}
              handleChange={handleChange}
            >
              {React.cloneElement(filter.input, {
                onChange: (e) => handleChange(filter.filtro, e.target.value),
              })}
            </FilterComponent>
          ))}
        </div>
      </Panel>
    </div>
  );
};
