import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartDoughnut = ({ data, colores, vistaAvanzada }) => {
  const [dataChart, setDatachart] = useState({
    labels: [],
    datasets: [],
  });

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: vistaAvanzada ? "Todos los contactos" : "Contactos efectivos",
        padding: {
          bottom: 16,
        },
        font: {
          size: 16,
          weight: "bold",
        },
      },
      datalabels: {
        display: "auto",
        color: "#222",
        backgroundColor: "rgba(255,255,255,.5)",
        borderRadius: 3,
        formatter: function (value, context) {
          return `${
            context.chart.data.datasets[0].porcent[context.dataIndex]
          }%`;
        },
      },
    },
  };

  useEffect(() => {
    const formattedData = {
      labels: data.map((d) => d.estado),
      datasets: [
        {
          data: data.map((d) => d.casos),
          porcent: data.map((d) => d.porcentaje),
          backgroundColor: colores,
          borderWidth: 1,
          borderColor: "transparent",
        },
      ],
    };
    setDatachart(formattedData);
  }, [data, colores]);

  return (
    <Doughnut data={dataChart} options={options} width={200} height={200} />
  );
};
